import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-utilities',
  templateUrl: './custom-utilities.component.html',
  styleUrls: ['./custom-utilities.component.scss']
})
export class CustomUtilitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
