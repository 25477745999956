import { InputStateIconsComponent } from './input-state-icons/input-state-icons.component';
import { PinnedHelperComponent } from './pinned-helper/pinned-helper.component';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from 'ngx-bootstrap/alert';
import { HighlightJsModule } from 'ngx-highlight-js';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ControlsInlineComponent } from './controls-inline/controls-inline.component';
import { CustomCardsComponent } from './custom-cards/custom-cards.component';
import { ResponsiveStatementsTableComponent } from './responsive-statements-table/responsive-statements-table.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TogglerComponent } from './toggler/toggler.component';
import { AtomsModule } from '../../shared/atoms/atoms.module';
import { DocumentImageComponent } from './document-image/document-image.component';
import { CustomUtilitiesComponent } from './custom-utilities/custom-utilities.component';
import { SpinnersComponent } from './spinners/spinners.component';

const routes: Routes = [
  {
    path: 'custom-components/pinned-helper',
    component: PinnedHelperComponent,
  },
  {
    path: 'custom-components/controls-inline',
    component: ControlsInlineComponent,
  },
  {
    path: 'custom-components/custom-cards',
    component: CustomCardsComponent
  },
  {
    path: 'custom-components/toggler',
    component: TogglerComponent,
  },
  {
    path: 'custom-components/responsive-statements-table',
    component: ResponsiveStatementsTableComponent
  },
  {
    path: 'custom-components/document-image',
    component: DocumentImageComponent
  },
  {
    path: 'custom-components/input-state-icons',
    component: InputStateIconsComponent
  },
  {
    path: 'utilities/absolute-positioning',
    component: CustomUtilitiesComponent
  },
  {
    path: 'bootstrap/spinners',
    component: SpinnersComponent
  },
];

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, HighlightJsModule, ReactiveFormsModule, RouterModule.forChild(routes), BsDropdownModule.forRoot(), AtomsModule],
  declarations: [PinnedHelperComponent, ControlsInlineComponent, CustomCardsComponent, TogglerComponent, ResponsiveStatementsTableComponent, DocumentImageComponent, InputStateIconsComponent, CustomUtilitiesComponent, SpinnersComponent],
  exports: [RouterModule, AlertModule, RouterModule, PinnedHelperComponent, ControlsInlineComponent, CustomCardsComponent, TogglerComponent, ResponsiveStatementsTableComponent, DocumentImageComponent],
})
export class CustomComponentsModule {

}
