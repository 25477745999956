/* tslint:disable:no-inferrable-types */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ActivatedRoute, Router } from '@angular/router';

interface ReceiverResponseWithDocument {
  id: number | null;
  surname: string | null;
  name: string | null;
  middlename: string | null;
  directionName: string;
}

interface CustomerLimits {
  currency: string;
  alias: string;
  amount: number;
  canSendAmount: number;
  limitAmount: number;
  isLimitExceed: boolean;
}

const LIMITS: CustomerLimits[] = [
  {
    "currency": "GBP",
    "alias": "DailyRemittance",
    "amount": 0,
    "canSendAmount": 27231,
    "limitAmount": 27231,
    "isLimitExceed": false
  },
  {
    "currency": "GBP",
    "alias": "WeeklyRemittance",
    "amount": 42,
    "canSendAmount": 27189,
    "limitAmount": 27231,
    "isLimitExceed": false
  },
  {
    "currency": "GBP",
    "alias": "MonthlyRemittance",
    "amount": 21469,
    "canSendAmount": 6306,
    "limitAmount": 27776,
    "isLimitExceed": false
  },
  {
    "currency": "GBP",
    "alias": "AnnualRemittance",
    "amount": 58981,
    "canSendAmount": 13635,
    "limitAmount": 72616,
    "isLimitExceed": false
  }
];

const RECEIVERS: ReceiverResponseWithDocument[] = [
  {
    "id": 155183,
    "surname": "BLACK",
    "name": "JACK",
    "middlename": null,
    "directionName": "Latvia Cash"
  },
  {
    "id": 155428,
    "surname": "TRIBIANI",
    "name": "JO",
    "middlename": null,
    "directionName": "Dominican Republic Cash (BancoUnion)"
  },
  {
    "id": 155531,
    "surname": "GITA",
    "name": "ZITA",
    "middlename": null,
    "directionName": "Ukraine (PrivatMoney)"
  },
  {
    "id": 155532,
    "surname": "HAT",
    "name": "ZITA",
    "middlename": null,
    "directionName": "Bangladesh Accounts WorkerApp "
  },
  {
    "id": 155571,
    "surname": "DIN",
    "name": "GIN",
    "middlename": null,
    "directionName": "India (RoyalMoney Account)"
  },
  {
    "id": 155651,
    "surname": "HELSING",
    "name": "VAN",
    "middlename": "",
    "directionName": "Romania Cash (BCR)"
  },
  {
    "id": 155674,
    "surname": "WORKER",
    "name": "PAKISTAN",
    "middlename": null,
    "directionName": "Pakistan Accounts WorkerApp "
  }
]

@Component({
  selector: 'app-pinned-helper',
  templateUrl: './pinned-helper.component.html',
  styleUrls: ['./pinned-helper.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class PinnedHelperComponent implements OnInit {

  @Output() agentDataClicked: EventEmitter<void> = new EventEmitter<void>();

  public status: boolean = false;

  public activeTab: 'success' | 'danger' | 'warning';

  hideLimits: boolean = false;
  hideReceivers: boolean = false;

  popoverReceivers: ReceiverResponseWithDocument[] = RECEIVERS;
  limits: CustomerLimits[] = LIMITS;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const activeTab = this.route.snapshot.queryParamMap.get('activeTab');
    if (activeTab && (activeTab === 'success'
      || activeTab === 'danger'
      || activeTab === 'warning')) {
      this.activeTab = activeTab;
    }
    console.log(activeTab);
  }

  showTab(newTab: 'success' | 'danger' | 'warning') {
    this.activeTab = newTab;
    this.router.navigate([], {
      queryParams: { activeTab: this.activeTab }
    });
  }

  clickEvent(): void {
    this.status = !this.status;
  }

  toggleElement(): void {
    this.agentDataClicked.emit(void 0);
  }

  contentLimitsToggle(): void {
    this.hideLimits = !this.hideLimits;
  }

  contentReceiversToggle(): void {
    this.hideReceivers = !this.hideReceivers;
  }

}
