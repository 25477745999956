import { Component } from '@angular/core';

@Component({
  selector: 'app-control-inline',
  templateUrl: './control-inline.component.html'
})
export class ControlInlineComponent {
  inputId: string;

  constructor() {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`;
  }
}
