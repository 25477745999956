import { Component } from '@angular/core';
import { AppSidebarService } from './app.service';
import { Observable } from 'rxjs';
import { MainQuery } from './app.store/app.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isShowPinnedSidebar$: Observable<boolean> = this._mainQ.isShowPinnedSidebar$;

  constructor(private _appService: AppSidebarService, private _mainQ: MainQuery) {}

  toggleSidebarPin(): void {
     this._appService.toggleSidebarPin();
  }
}
