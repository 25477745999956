import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ff-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() spinnerStyle: 'border' | 'grow';
  @Input() color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  @Input() size: 'sm' | 'md' | 'lg';

  constructor() { }

  ngOnInit() {
  }

  calculateStyles() {
    if (this.size === 'md') {
      return {
        'width': '3em',
        'height': '3em'
      };
    }
    if (this.size === 'lg') {
      return {
        'width': '4em',
        'height': '4em'
      };
    }
    return {};
  }

}
