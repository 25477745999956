import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./pages/home/home.component";
import { DemoComponent } from "./pages/demo/demo.component";
import { AlertsComponent } from './components/pages/bootstrap/alerts/alerts.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'bootstrap',
    component: HomeComponent
  },
  {
    path: 'demo',
    component: DemoComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
