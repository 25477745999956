import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { DemoComponent } from './pages/demo/demo.component';
import { NavbarComponent } from './components/pages/bootstrap/navbar/navbar.component';
import { SelectLanguageComponent } from './components/app/select-theme/select-theme.component';
import { BootstrapModule } from './components/pages/bootstrap/bootstrap.module';
import { HighlightJsModule } from 'ngx-highlight-js';
import { AtomsModule } from './components/shared/atoms/atoms.module';
import { CustomComponentsModule } from './components/pages/custom-components/custom-components.module';
import { OrganismsModule } from './components/shared/organisms/organisms.module';
import { ThirdPartyComponentsModule } from './components/pages/third-party-components/third-party-components.module';

import { SharedModule } from './components/shared/shared.module';
import { akitaDevtools } from '@datorama/akita';
akitaDevtools();
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DemoComponent,
    NavbarComponent,
    SelectLanguageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BootstrapModule,
    HighlightJsModule,
    AtomsModule,
    CustomComponentsModule,
    ThirdPartyComponentsModule,
    OrganismsModule,
    SharedModule,
    FormsModule,
    NgBootstrapFormValidationModule.forRoot()
  ],
  providers: [],
  exports: [AtomsModule, CustomComponentsModule, OrganismsModule, ThirdPartyComponentsModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
