import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { selectOption } from '../../../shared/atoms/select/select.component';

const defaultSelectValue: selectOption[] = [
  {
    title: 1,
    value: 1
  },
  {
    title: 2,
    value: 2
  },
  {
    title: 3,
    value: 3
  },
  {
    title: 4,
    value: 4
  },
  {
    title: 5,
    value: 5
  },
];

const defaultInputValue: string = 'Lorem Ipsum';
const defaultInputNumberValue: number = 100500;
const defaultInputDateValue: string = '12.10.1991';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})
export class InputsComponent implements OnInit { // TODO: Refactor to ControlValueAccessor
  defaultSelectValue: selectOption[];
  defaultInputValue: string;
  defaultInputNumberValue: number;


  public form = new FormGroup(
    {
      input: new FormControl(defaultInputValue, [Validators.required]),
      inputDisabled: new FormControl({ value: defaultInputValue, disabled: true }, [Validators.required]),

      inputNumber: new FormControl(defaultInputNumberValue, [Validators.required]),
      inputNumberDisabled: new FormControl({ value: defaultInputNumberValue, disabled: true }, [Validators.required]),

      inputDate: new FormControl(defaultInputDateValue, [Validators.required]),
      inputDateDisabled: new FormControl({ value: defaultInputDateValue, disabled: true }, [Validators.required]),

      select: new FormControl(defaultSelectValue, [Validators.required]),
      selectDisabled: new FormControl({ value: defaultSelectValue, disabled: true }, [Validators.required]),

      checkboxLg: new FormControl(true, [Validators.required]),
      checkbox: new FormControl(false, [Validators.required]),
      checkboxSm: new FormControl(true, [Validators.required]),
      checkboxDisabled: new FormControl({ value: true, disabled: true }),

      radio: new FormControl('male', [Validators.required]),
      radioDisabled: new FormControl({ value: 'male', disabled: true }, [Validators.required]),
      switch: new FormControl(true, [Validators.required]),
      switchDisabled: new FormControl({ value: true, disabled: true }, [Validators.required]),
      range: new FormControl(2, [Validators.required]),
      rangeDisabled: new FormControl({ value: 4, disabled: true }, [Validators.required]),
      file: new FormControl(null, [Validators.required])
    }
  );

  constructor() {
    this.defaultSelectValue = defaultSelectValue;
    this.defaultInputValue = defaultInputValue;
    this.defaultInputNumberValue = defaultInputNumberValue;
  }

  ngOnInit() {
  }

}
