import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeComponent),
      multi: true
    }
  ]
})
export class RangeComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() validTooltip: string;
  @Input() invalidTooltip: string;

  inputId: string;

  constructor(private _element: ElementRef, private  _renderer: Renderer2) {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      this._renderer.setProperty(this._element.nativeElement.getElementsByTagName('input')[0], 'disabled', isDisabled);
    });
  }

  writeValue(value: string): void {

    this.onChange(value);
  }

}
