import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';


@Component({
  selector: 'app-custom-range',
  templateUrl: './custom-range.component.html',
  styleUrls: ['./custom-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomRangeComponent implements OnChanges, AfterViewInit {

  @Input() max: number = 100;
  @Input() min: number = 0;
  @Input() from: string = '00:00';
  @Input() to: string = '23:59';
  @Input() step: number = 2.083333;
  @Input() isDisabled: boolean = false;
  fromValue: number = 0;
  toValue: number = 0;

  @Output() updateFrom: EventEmitter<{ startTime: string }> = new EventEmitter<{ startTime: string }>();
  @Output() updateTo: EventEmitter<{ endTime: string }> = new EventEmitter<{ endTime: string }>();

  @ViewChild('sliderBar', { static: false }) sliderBar: ElementRef;
  @ViewChild('fromInput', { static: false }) fromInput: ElementRef;
  @ViewChild('toInput', { static: false }) toInput: ElementRef;

  ngOnChanges(): void {
    if (this.fromInput && this.toInput && this.checkValueValidity() && !this.checkIsDistinctValues()) {
      const minutesFrom = this.from != null ? (parseFloat(this.from.split(':')[0]) * 60 + parseFloat(this.from.split(':')[1])) : null;
      const minutesTo = this.to != null ? (parseFloat(this.to.split(':')[0]) * 60 + parseFloat(this.to.split(':')[1])) : null;
      if (minutesFrom < minutesTo) {
        this.setInputValue(minutesFrom, minutesTo);
        this.drawTimeRange();
        this.onInputFrom(this.fromInput.nativeElement, false);
        this.onInputTo(this.toInput.nativeElement, false);
      }
    }
  }

  ngAfterViewInit(): void {
    const minutesFrom = this.from != null ? (parseFloat(this.from.split(':')[0]) * 60 + parseFloat(this.from.split(':')[1])) : null;
    const minutesTo = this.to != null ? (parseFloat(this.to.split(':')[0]) * 60 + parseFloat(this.to.split(':')[1])) : null;
    if (this.fromInput && this.toInput) {
      if (minutesFrom < minutesTo) {
        this.setInputValue(minutesFrom, minutesTo);
        this.drawTimeRange();
        this.onInputFrom(this.fromInput.nativeElement, false);
        this.onInputTo(this.toInput.nativeElement, false);
      }
    }
  }

  onInputFrom(fromInput: HTMLInputElement, isEmitValue: boolean): void {
    const toValue = Math.round(this.toValue / 30 * this.step);
    const value = Math.min(toValue, parseFloat(fromInput.value));
    if (value < toValue) {
      this.fromValue = Math.round(value / this.step * 30);
      const children = this.sliderBar.nativeElement.childNodes;
      children[0].style.width = value + '%';
      children[2].style.left = value + '%';
      children[3].style.left = value + '%';
      children[5].style.left = 'calc(' + value + '% - 10px)';
      this.from = this.convertValueToTimeString(this.fromValue);
      if (isEmitValue) {
        this.updateFrom.emit({ startTime: this.from });
      }
    } else {
      fromInput.value = ((this.fromValue - 30) / 30 * this.step).toString();
    }
  }

  onInputTo(toInput: HTMLInputElement, isEmitValue: boolean): void {
    const fromValue = Math.round(this.fromValue / 30 * this.step);
    const value = Math.max(fromValue, parseFloat(toInput.value));
    if (value > fromValue) {
      this.toValue = Math.round(value / this.step * 30);
      const children = this.sliderBar.nativeElement.childNodes;
      children[1].style.width = 100 - value + '%';
      children[2].style.right = 100 - value + '%';
      children[4].style.left = value + '%';
      children[6].style.left = 'calc(' + value + '% + 10px)';
      this.to = this.convertValueToTimeString(this.toValue);
      if (isEmitValue) {
        this.updateTo.emit({ endTime: this.to });
      }
    } else {
      toInput.value = ((this.toValue + 30) / 30 * this.step).toString();
    }
  }

  private convertValueToTimeString(value: number): string {
    const hours = value === 1440 ? 23 : Math.floor(value / 30 / 2);
    const hoursString = hours >= 10 ? hours.toString() : '0' + hours;
    const minutes = value === 1440 ? 59 : (value - hours * 2 * 30);
    const minutesString = minutes >= 10 ? minutes.toString() : '0' + minutes;
    return `${hoursString}:${minutesString}`;
  }

  private setInputValue(from: number, to: number): void {
    this.fromValue = from;
    this.toValue = to;
    this.fromInput.nativeElement.value = (Math.round(this.fromValue / 30) * this.step).toString();
    this.toInput.nativeElement.value = (Math.round(this.toValue / 30) * this.step).toString();
  }

  private drawTimeRange(): void {
    const children = this.sliderBar.nativeElement.childNodes;
    const from = Math.round(this.fromValue / 30) * this.step;
    children[0].style.width = from + '%';
    children[2].style.left = from + '%';
    children[3].style.left = from + '%';
    children[5].style.left = 'calc(' + from + '% - 10px)';

    const to = Math.round(this.toValue / 30) * this.step;
    children[1].style.width = 100 - to + '%';
    children[2].style.right = 100 - to + '%';
    children[4].style.left = to + '%';
    children[6].style.left = 'calc(' + to + '% + 10px)';
  }

  private checkIsDistinctValues(): boolean {
    const minutesFrom = this.from != null ? (parseFloat(this.from.split(':')[0]) * 60 + parseFloat(this.from.split(':')[1])) : null;
    const minutesTo = this.to != null ? (parseFloat(this.to.split(':')[0]) * 60 + parseFloat(this.to.split(':')[1])) : null;
    return this.fromValue === minutesFrom && this.toValue === minutesTo;
  }

  private checkValueValidity(): boolean {
    const fromSplitted = this.from.split(':');
    const isValidFrom = this.from != null && fromSplitted.length === 2 && fromSplitted[0].length === 2 && fromSplitted[1].length === 2;
    const toSplitted = this.to.split(':');
    const isValidTo = this.to != null && toSplitted.length === 2 && toSplitted[0].length === 2 && toSplitted[1].length === 2;
    return isValidFrom && isValidTo;
  }
}
