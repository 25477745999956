import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html'
})
export class JumbotronComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  onClosed(alert: any) {

  }
}
