import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightJsModule } from 'ngx-highlight-js';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AtomsModule } from '../../shared/atoms/atoms.module';

const routes: Routes = [
  {
    path: 'third-party/ng-wizard',
    loadChildren: () => import('./ng-wizard/ng-wizard-page.module').then(m => m.NgWizardPageModule)
  }
];

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, HighlightJsModule, ReactiveFormsModule, RouterModule.forChild(routes), BsDropdownModule.forRoot(), AtomsModule],
  declarations: [],
  exports: [RouterModule],
})
export class ThirdPartyComponentsModule {

}
