import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true
    }
  ]
})
export class RadioComponent implements ControlValueAccessor {
  inputId: string;
  checked: boolean;

  @Input() size: 'lg' | 'sm' | null;
  @Input() label: string;
  @Input() topLabel: string;
  @Input() required: boolean = false;
  @Input() checkboxStyle: 'inline' | 'outline';
  @Input() value: string;
  @Input() validTooltip: string;
  @Input() invalidTooltip: string;

  constructor(private _element: ElementRef, private  _renderer: Renderer2) {
    this.inputId = `input${Math.trunc(Math.random() * 500100)}`;
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      this._renderer.setProperty(this._element.nativeElement.getElementsByTagName('input')[0], 'disabled', isDisabled);
    });
  }

  writeValue(value: any): void {
    if (!value || typeof value !== 'string') {
      return;
    }
  }
}
