import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { RadioComponent } from './radio/radio.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwitchComponent } from './switch/switch.component';
import { RangeComponent } from './range/range.component';
import { CustomRangeComponent } from './custom-range/custom-range.component';
import { FileComponent } from './file/file.component';
import { TimeRangePipe } from './custom-range/time-range.pipe';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ControlInlineComponent } from './control-inline/control-inline.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { SearchInputComponent } from './search-input/search-inputcomponent';
import { CustomTogglerComponent } from './custom-toggler/custom-toggler.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExclamationInputComponent } from './exclamation-input/exclamation-input.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    RadioComponent,
    CheckboxComponent,
    SwitchComponent,
    RangeComponent,
    CustomRangeComponent,
    FileComponent,
    TimeRangePipe,
    ControlInlineComponent,
    CustomInputComponent,
    SearchInputComponent,
    CustomTogglerComponent,
    ExclamationInputComponent,
    SpinnerComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgBootstrapFormValidationModule
  ],
  providers: [],
  exports: [
    InputComponent,
    SelectComponent,
    RadioComponent,
    CheckboxComponent,
    SwitchComponent,
    RangeComponent,
    FileComponent,
    CustomRangeComponent,
    ControlInlineComponent,
    CustomInputComponent,
    SearchInputComponent,
    CustomTogglerComponent,
    ExclamationInputComponent,
    SpinnerComponent
  ]
})
export class AtomsModule {
}
