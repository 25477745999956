import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

const defaultInputValue: string = 'Search';

@Component({
  selector: 'app-search-input-page',
  templateUrl: './search-input.component.html'
})
export class SearchInputPageComponent implements OnInit {

  defaultInputValue: string;

  public searchForm: FormGroup = new FormGroup({
    search: new FormControl(defaultInputValue, [Validators.required]),
    searchDisabled: new FormControl({ value: defaultInputValue, disabled: true }, [Validators.required]),
  });

  constructor() {
    this.defaultInputValue = defaultInputValue;
  }

  ngOnInit() {
  }

}
