import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ]
})

export class SearchInputComponent  implements ControlValueAccessor {
  inputId: string;


  @Input() label: string;
  @Input() placeholder: string;
  @Input() size: 'sm' | 'lg' | null;

  @Input() type: 'custom-search-input' | null;

  @Input() tooltip: string;

  constructor(private _element: ElementRef, private  _renderer: Renderer2) {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`;
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };
  value: string | null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    setTimeout(() => {
      this._renderer.setProperty(this._element.nativeElement.getElementsByTagName('input')[0], 'disabled', isDisabled);
     });
  }

  writeValue(value: string): void {
    if (!value || typeof value !== 'string') {
      return;
    }

    this.onChange(value);
  }


}

