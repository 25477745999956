import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AppSidebarService } from '../../app.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Router } from '@angular/router';

declare const STABLE_FEATURE: boolean;
declare const EXPERIMENTAL_FEATURE: boolean;

interface IComponentObj {
  name?: string;
  title: string;
  link?: string;
}
const bootstrapComponents: IComponentObj[] = [
  {
    title: '',
    name: 'Bootstrap',
    link: 'bootstrap'
  },
  {
    title: 'Alert',
    link: 'bootstrap/alerts'
  },
  {
    title: 'Typography',
    link: 'bootstrap/typography'
  },
  {
    title: 'Badges',
    link: 'bootstrap/badges'
  },
  {
    title: 'Buttons',
    link: 'bootstrap/buttons'
  },
  {
    title: 'Forms',
    link: 'bootstrap/inputs'
  },
  {
    title: 'Tabs',
    link: 'bootstrap/tabs'
  },
  {
    title: 'Card',
    link: 'bootstrap/card'
  },
  {
    title: 'Jumbotron',
    link: 'bootstrap/jumbotron'
  },
  {
    title: 'Form group',
    link: 'bootstrap/form-group'
  },
  {
    title: 'Search input',
    link: 'bootstrap/search-input'
  },
  {
    title: 'Progressbar',
    link: 'bootstrap/progressbar'
  },
  {
    title: 'Spinners',
    link: 'bootstrap/spinners'
  },
  {
    title: 'Input state icons',
    link: 'bootstrap/input-state-icons'
  }
];

const customComponents: IComponentObj[] = [
  {
    title: '',
    name: 'Custom components'
  },
  {
    title: 'Pinned helper',
    link: 'custom-components/pinned-helper'
  },
  {
    title: 'Inline Control',
    link: 'custom-components/controls-inline'
  },
  {
    title: 'Cards',
    link: 'custom-components/custom-cards'
  },
  {
    title: 'Toggler',
    link: 'custom-components/toggler'
  },
  {
    title: 'Responsive Statements Table',
    link: 'custom-components/responsive-statements-table'
  },
  {
    title: 'Document images',
    link: 'custom-components/document-image'
  },
  {
    title: 'Input state icons',
    link: 'custom-components/input-state-icons'
  }
];

const utilities: IComponentObj[] = [
  {
    title: '',
    name: 'Utilities'
  },
  {
    title: 'Absolute positioning',
    link: 'utilities/absolute-positioning'
  }
]

const thirdParty: IComponentObj[] = [
  {
    title: '',
    name: 'Connected third-party libs',
  },
  {
    title: 'NG-WIZARD',
    link: 'third-party/ng-wizard'
  }
];

const allComponents = [...bootstrapComponents, ...customComponents, ...utilities, ...thirdParty];

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit, OnDestroy {
  titleSubscription: Subscription;
  stableFeature: string;
  experimentalFeature: string;
  bootstrapComponentList = [];
  customComponentList = [];
  thirdPartyComponentList = [];
  allComponentsList$: BehaviorSubject<IComponentObj[]> = new BehaviorSubject<IComponentObj[]>(allComponents);

  public formOne: FormGroup = new FormGroup({
    title: new FormControl(null, Validators.required),
  });

  constructor(
    private appSidebarService: AppSidebarService,
    private router: Router
  ) {
    this.bootstrapComponentList = bootstrapComponents;
    this.customComponentList = customComponents;
    this.thirdPartyComponentList = thirdParty;
  }

  ngOnInit() {
    this.titleSubscription = this.formOne.controls.title.valueChanges
      .pipe(
        tap((value: IComponentObj) => {
          if (value == null) {
            this.allComponentsList$.next(allComponents);
            return;
          }
          const title = value.title;
          const filtered: IComponentObj[] = allComponents.filter(c => {
            const a = c.title.toLowerCase();
            const b = title.toLowerCase();
            return a.includes(b);
          });
          if (filtered.length === 0) {
            this.allComponentsList$.next(allComponents);
          } else {
            this.allComponentsList$.next(filtered);
          }
        })
      )
      .subscribe();

    this.stableFeature = STABLE_FEATURE ? 'Stable feature enabled' : 'Stable feature disabled';
    this.experimentalFeature = EXPERIMENTAL_FEATURE ? 'Experimental feature enabled' : 'Experimental feature disabled';
  }
  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
  }

  onRouteClick(link: string): void {
    of(0)
      .pipe(
        switchMap(() => fromPromise(this.router.navigate([link])))
      )
      .subscribe();
  }
}
