import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, DefaultValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})

export class InputComponent implements ControlValueAccessor {
  inputId: string;

  @Input() label: string;

  @Input() placeholder: string;
  @Input() inputType: 'text' | 'number' | 'password' | 'email' | 'date' | 'textarea';
  @Input() size: 'sm' | 'lg' | null;
  @Input() isValid: boolean;
  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() readonly: boolean;
  @Input() validTooltip: string;
  @Input() invalidTooltip: string;

  @Input() cols: number | null;
  @Input() rows: number | null;

  constructor(private _element: ElementRef, private  _renderer: Renderer2) {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`;
    this.inputType = this.inputType != null ? this.inputType : 'text';
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };
  value: string | null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      this._renderer.setProperty(this._element.nativeElement.getElementsByTagName('input')[0], 'disabled', isDisabled);
    });
  }

  writeValue(value: string): void {
    if (!value || typeof value !== 'string') {
      return;
    }

    this.value = value;
    this.onChange(value);
  }

}

