import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'app-select-theme',
  templateUrl: './select-theme.component.html',
  styleUrls: ['./select-theme.component.scss']
})
export class SelectLanguageComponent implements OnInit {

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.themeService.setTheme('theme-bestfinance');
  }

  setTheme(event): void {
    console.log(event.target.value);
    this.themeService.setTheme(event.target.value);
  }
}
