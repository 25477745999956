import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeRange'
})
export class TimeRangePipe implements PipeTransform {
  constructor() { }

  transform(value: number): string {
    const hours = value === 1440 ? 23: Math.floor(value / 30 / 2);
    const hoursString = hours >= 10 ? hours.toString() : '0' + hours;
    const minutes = value === 1440 ? 59 : (value - hours * 2 * 30);
    const minutesString = minutes >= 10 ? minutes.toString() : '0' + minutes;
    return `${hoursString}:${minutesString}`;
  }
}
