import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PinnedHelperComponent } from '../../../pages/custom-components/pinned-helper/pinned-helper.component';

export const Animations = [
  trigger('isDivHidden', [
    state('initial', style({
      right: '{{rightInitial}}px',
      width: '{{width}}px',
    }), { params: { rightInitial: 0, rightHidden: -266, width: 0 } }),
    state('hidden', style({
      right: '{{rightHidden}}px',
    }), { params: { rightInitial: 0, rightHidden: -266, width: 0 } }),
    transition('initial=>hidden', animate('300ms ease-in')),
    transition('hidden=>initial', animate('300ms ease-out')),
  ])
];

@Component({
  selector: 'app-animated-popover',
  templateUrl: './animated-popover.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations
})
export class AnimatedPopoverComponent implements OnInit, OnDestroy {
  @ViewChild('pinnedHelperComponent', { static: false }) private balanceWidget: PinnedHelperComponent;
  animationParams: {[name: string]: any; }  = { timing: 300 , rightInitial: 0, rightHidden: -283, width: 300 };
  isDivHidden$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  animationState$: Observable<string> = this.isDivHidden$.pipe(
    map(isHiding => isHiding ? 'hidden' : 'initial')
  );

  @Input() animationState: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

  onAgentClicked(): void {
    this.isDivHidden$.next(!this.isDivHidden$.getValue());
  }

}
