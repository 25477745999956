import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightJsModule } from 'ngx-highlight-js';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { AnimatedPopoverComponent } from './animated-popover/animated-popover.component';
import { CustomComponentsModule } from '../../pages/custom-components/custom-components.module';
import { CustomCardComponent } from './custom-card/custom-card.component';


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HighlightJsModule,
    ReactiveFormsModule,
    CustomComponentsModule
  ],
  declarations: [
    AnimatedPopoverComponent,
    CustomCardComponent
  ],
  exports: [
    RouterModule,
    AnimatedPopoverComponent,
    CustomCardComponent
  ],
})
export class OrganismsModule {

}
