import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

interface Statement {
  id: string;
  number: string;
  name: string;
  dayLimit: number;
  minimumBalance: number;
  overdraftLimit: number;
  currentBalance: number;
  enable: boolean;
  isDefault: boolean;
  accountType: string;
  currency: string;
  clearingCurrentBalance: number;
  settlementCurrency: string;
}

const STATEMENTS: Statement[] = [
  {
    id:"e5cb34cc-548d-4e4d-81e6-0112c660fa4d",
    number:"30218.840.0000.26.71974",
    name: "RemittanceClearing USD",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:-3501871.4700,
    enable:true,
    isDefault:true,
    accountType: "RemittanceClearing",
    currency:"USD",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"04c79fb6-d27d-4d71-874f-4b55bb265256",
    number:"40702.840.0000.26.71974",
    name:"RemittanceAgentLORO USD",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:50000000000.0000,
    currentBalance:2979131.5192,
    enable:true,
    isDefault:true,
    accountType:"RemittanceAgentLORO",
    currency:"USD",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"9b2ae505-2f8d-423a-94c1-5a275780ef16",
    number:"30218.985.0000.26.71974",
    name:"RemittanceClearing PLN",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:0.0000,
    enable:true,
    isDefault:false,
    accountType:"RemittanceClearing",
    currency:"PLN",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"aca64330-7ea9-41b7-be65-5bd84cc2f727",
    number:"30218.826.0000.26.71974",
    name:"RemittanceClearing GBP",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:566338.7200,
    enable:true,
    isDefault:false,
    accountType:"RemittanceClearing",
    currency:"GBP",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"39dfede0-1943-4cd3-88ad-a0d9a22cf2c6",
    number:"40702.826.0000.26.71974",
    name:"RemittanceAgentLORO GBP",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:-304268.0700,
    enable:true,
    isDefault:false,
    accountType:"RemittanceAgentLORO",
    currency:"GBP",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"390f1025-c261-4a69-a687-a6b736a74211",
    number:"40702.985.0000.26.71974",
    name:"RemittanceAgentLORO PLN",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:0.0000,
    enable:true,
    isDefault:false,
    accountType:"RemittanceAgentLORO",
    currency:"PLN",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"196d5b92-2c38-4c82-a126-be1b8d9d0489",
    number:"47423.826.0000.26.71974",
    name:"RemittanceAgentNOSTRO GBP",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:-292705.5438,
    enable:true,
    isDefault:false,
    accountType:"RemittanceAgentNOSTRO",
    currency:"GBP",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"b30bf367-c91a-468c-985d-d5e3269341ea",
    number:"30218.978.0000.26.71974",
    name:"RemittanceClearing EUR",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:-134899.5500,
    enable:true,
    isDefault:false,
    accountType:"RemittanceClearing",
    currency:"EUR",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"92fecfa3-6df1-4afa-82cd-d6f9b0d188c8",
    number:"40702.978.0000.26.71974",
    name:"RemittanceAgentLORO EUR",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:105206.2500,
    enable:true,
    isDefault:false,
    accountType:"RemittanceAgentLORO",
    currency:"EUR",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"de0f49c2-03ec-4cde-89dc-fa50008d913c",
    number:"30218.608.0000.26.71974",
    name:"RemittanceClearing PHP",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:0.0000,
    enable:true,
    isDefault:false,
    accountType:"RemittanceClearing",
    currency:"PHP",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  },
  {
    id:"a8fae5a2-28c6-4f6c-85d1-fdba9fdbd6ec",
    number:"47423.608.0000.26.71974",
    name:"RemittanceAgentNOSTRO PHP",
    dayLimit:null,
    minimumBalance:null,
    overdraftLimit:null,
    currentBalance:5620.0000,
    enable:true,
    isDefault:false,
    accountType:"RemittanceAgentNOSTRO",
    currency:"PHP",
    clearingCurrentBalance: 0,
    settlementCurrency: "EUR"
  }];

const LIMITS: number[] = [10, 20, 30];

@Component({
  selector: 'app-responsive-statements-table',
  templateUrl: './responsive-statements-table.component.html',
  styleUrls: []
})
export class ResponsiveStatementsTableComponent implements OnInit, OnDestroy, AfterViewInit {

  private unsubscribe$ = new Subject();

  statements$: BehaviorSubject<Statement[]> = new BehaviorSubject<Statement[]>(STATEMENTS);
  limits: number[] = LIMITS;
  total: number = STATEMENTS.length;
  limit$: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  displayedItemsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  selectedStatement$: BehaviorSubject<Statement> = new BehaviorSubject<Statement>(null);
  displayedItems$: Observable<Statement[]> = combineLatest([this.statements$, this.displayedItemsCount$]).pipe(
    takeUntil(this.unsubscribe$),
    map(([statements, displayedItemsCount]) => statements.slice(0, displayedItemsCount))
  );

  screenSize: number;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  showMore(): void {
    this.displayedItemsCount$.next(this.displayedItemsCount$.getValue() + this.limit$.getValue());
  }

  changePageLimit(limit: number): void {
    this.limit$.next(limit);
    if (limit > this.displayedItemsCount$.getValue()) {
      this.displayedItemsCount$.next(limit);
    }
  }

  onSortClick(): void {
  }

  onRowClicked(statement: Statement): void {
    this.selectedStatement$.next(this.selectedStatement$.value != null && this.selectedStatement$.value.id === statement.id ? null : statement);
  }

  @HostListener('window:resize', [])
  private onResize(): void {
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    this.screenSize = window.innerWidth;
  }
}
