import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IMainState {
  isMenuOpened: boolean;
}

export function createInitialState(): IMainState {
  return {
    isMenuOpened: true
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'main' })
export class MainStore extends Store<IMainState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({
  providedIn: 'root'
})
export class MainQuery extends Query<IMainState> {

  public isShowPinnedSidebar$: Observable<boolean> = this.select(s => !s.isMenuOpened);
  public isMenuOpened$: Observable<boolean> = this.select(s => s.isMenuOpened);

  constructor(protected store: MainStore) {
    super(store);
  }
}
