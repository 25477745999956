import { Injectable } from '@angular/core';
import { MainStore } from './app.store/app.store';
import { logAction } from '@datorama/akita';

@Injectable({
  providedIn: 'root'
})
export class AppSidebarService {

  constructor(private _mainStore: MainStore) {}

  toggleSidebarPin(): void {
    logAction('ToggleSidebarPin');
    this._mainStore.update(st => ({isMenuOpened: !st.isMenuOpened}));
  }

}
