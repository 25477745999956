import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true
    }
  ]
})

export class CustomInputComponent  implements ControlValueAccessor {
  inputId: string;


  @Input() label: string;
  @Input() placeholder: string;
  @Input() size: 'sm' | 'lg' | null;
  @Input() isValid: boolean;
  @Input() readonly: boolean;
  @Input() validTooltip: string;
  @Input() invalidTooltip: string;

  @Input() rateCurrency: string;

  constructor(private _element: ElementRef, private  _renderer: Renderer2) {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`;
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };
  value: string | null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    setTimeout(() => {
      this._renderer.setProperty(this._element.nativeElement.getElementsByTagName('input')[0], 'disabled', isDisabled);
      (isDisabled) ? this._renderer.addClass(this._element.nativeElement.getElementsByClassName('input-group-append')[0], 'disabled') : this._renderer.removeClass(this._element.nativeElement.getElementsByClassName('input-group-append')[0], 'disabled');
    });
  }

  writeValue(value: string): void {
    if (!value || typeof value !== 'string') {
      return;
    }

    this.value = value;
    this.onChange(value);
  }


}

