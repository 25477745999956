import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

type THEME = 'theme-bestfinance' | 'theme-finflow' | 'theme-kaymaks' | 'theme-bpremit' | 'theme-bestpay';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _mainTheme$: BehaviorSubject<string> = new BehaviorSubject<string>('theme-bestfinance');

  private _renderer: Renderer2;
  private head: HTMLElement;
  private themeLinks: HTMLElement[] = [];

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) document: Document
  ) {
    this.head = document.head;
    this._renderer = rendererFactory.createRenderer(null, null);

    this._mainTheme$.subscribe(async themeName => {
      const cssExt = 'css';
      const cssFileName = `${themeName}.${cssExt}`;

      await this.loadCss(cssFileName);

      if (this.themeLinks.length == 2) {
        this._renderer.removeChild(this.head, this.themeLinks.shift());
      }
    });
  }

  setTheme(name: THEME): void {
    this._mainTheme$.next(name);
  }

  private async loadCss(filename: string) {
    return new Promise(resolve => {
      const linkEl: HTMLElement = this._renderer.createElement('link');
      this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      this._renderer.setAttribute(linkEl, 'type', 'text/css');
      this._renderer.setAttribute(linkEl, 'href', filename);
      this._renderer.setProperty(linkEl, 'onload', resolve);
      this._renderer.appendChild(this.head, linkEl);
      this.themeLinks = [...this.themeLinks, linkEl];
    })
  }
}
