import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-toggler',
  templateUrl: './custom-toggler.component.html'
})

export class CustomTogglerComponent implements OnInit {

  public show: boolean = true;
  public isSourceDestinationMode: boolean = true;

  @Input() firstTitle: string;
  @Input() secondTitle: string;

  constructor() { }

  ngOnInit() {
  }

  toggleCalculatorType(show: boolean) {
    this.show = !this.show;
    this.isSourceDestinationMode = !this.isSourceDestinationMode;
  }

}

