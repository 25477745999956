import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor {
  inputId: string;
  checked: boolean;

  @Input() size: 'lg' | 'sm' | null;
  @Input() formControlRef: AbstractControl | FormControl;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() checkboxStyle: 'inline' | 'outline';
  @Input() validTooltip: string;
  @Input() invalidTooltip: string;

  constructor(private _element: ElementRef, private  _renderer: Renderer2) {
    this.inputId = `input${Math.trunc(Math.random() * 500100)}`;
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      this._renderer.setProperty(this._element.nativeElement.getElementsByTagName('input')[0], 'disabled', isDisabled);
    });
  }

  writeValue(value: string): void {
    this.onChange(value);
  }
}
