import { Component, ElementRef, forwardRef, Input, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface selectOption {
  title: string | number;
  value: string | number;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor {
  inputId: string;

  @Input() placeholder: string;
  @Input() label: string;
  @Input() size: 'sm' | 'lg';
  @Input() options: selectOption[];
  @Input() isValid: boolean;
  @Input() multiple: boolean;
  @Input() required: boolean;
  @Input() validTooltip: string;
  @Input() invalidTooltip: string;

  @Output() value: string;

  constructor(private _element: ElementRef, private  _renderer: Renderer2) {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    if (!value || typeof value !== 'string') {
      return;
    }

    this.onChange(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._element.nativeElement.getElementsByTagName('select')[0], 'disabled', isDisabled);
  }
}
