import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent {
  inputId: string;
  @Input() formControlRef: AbstractControl | FormControl;
  @Input() label: string;
  @Input() uploadBtnText: string;
  @Input() validTooltip: string;
  @Input() invalidTooltip: string;

  constructor() {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`
  }
}
