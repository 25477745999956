import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-groups',
  templateUrl: './form-groups.component.html',
  styleUrls: ['./form-groups.component.scss']
})
export class FormGroupsComponent implements OnInit {
  formGroup: FormGroup;


  constructor() { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      firstName: new FormControl('Mark', [Validators.required, Validators.minLength(8),
        Validators.maxLength(20)]),
      lastName: new FormControl('Otto', [Validators.required, Validators.minLength(8),
        Validators.maxLength(20)]),
      city: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      zip: new FormControl(null, [Validators.required])
    });


    this.formGroup.valueChanges.subscribe()
  }

  onFormSubmit(): void {
    console.log('On form submit action');
  }
}
