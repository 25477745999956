import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AlertsComponent } from './alerts/alerts.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { HighlightJsModule } from 'ngx-highlight-js';
import { BadgesComponent } from './badges/badges.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputsComponent } from './inputs/inputs.component';
import { AtomsModule } from '../../shared/atoms/atoms.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroupsComponent } from './form-groups/form-groups.component';
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsComponent } from './tabs/tabs.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CardComponent } from './card/card.component';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { SearchInputPageComponent } from './search-input/search-input.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { TypographyComponent } from './typography/typography.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SpinnersComponent } from '../custom-components/spinners/spinners.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: 'bootstrap/alerts',
    component: AlertsComponent,
  },
  {
    path: 'bootstrap/typography',
    component: TypographyComponent,
  },
  {
    path: 'bootstrap/tabs',
    component: TabsComponent,
  },
  {
    path: 'bootstrap/navbar',
    component: NavbarComponent
  },
  {
    path: 'bootstrap/badges',
    component: BadgesComponent
  },
  {
    path: 'bootstrap/buttons',
    component: ButtonsComponent
  },
  {
    path: 'bootstrap/inputs',
    component: InputsComponent
  },
  {
    path: 'bootstrap/search-input',
    component: SearchInputPageComponent
  },
  {
    path: 'bootstrap/form-group',
    component: FormGroupsComponent
  },
  {
    path: 'bootstrap/card',
    component: CardComponent
  },
  {
    path: 'bootstrap/jumbotron',
    component: JumbotronComponent
  },
  {
    path: 'bootstrap/progressbar',
    component: ProgressbarComponent
  }
];

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, AtomsModule, RouterModule.forChild(routes), AlertModule.forRoot(), TabsModule.forRoot(), HighlightJsModule, ButtonsModule.forRoot(), BsDropdownModule.forRoot(),  ProgressbarModule.forRoot(), ReactiveFormsModule, NgBootstrapFormValidationModule],
  exports: [RouterModule, AlertModule, AlertsComponent, BadgesComponent, ButtonsComponent, InputsComponent, FormGroupsComponent, SearchInputPageComponent, TabsComponent, CardComponent, JumbotronComponent, TypographyComponent, ProgressbarComponent],
  declarations: [
    AlertsComponent,
    BadgesComponent,
    ButtonsComponent,
    InputsComponent,
    FormGroupsComponent,
    SearchInputPageComponent,
    TabsComponent,
    CardComponent,
    JumbotronComponent,
    TypographyComponent,
    ProgressbarComponent
  ]
})
export class BootstrapModule {

}
