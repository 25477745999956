import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-exclamation-input',
  templateUrl: './exclamation-input.component.html',
  styleUrls: ['./exclamation-input.component.scss']
})
export class ExclamationInputComponent {
  inputId: string;
  @Input() formControlRef: AbstractControl | FormControl;
  @Input() label: string;
  @Input() btnText: string;
  @Input() state: 'success' | 'warning' | 'danger';
  @Input() disabled: boolean;

  constructor() {
    this.inputId = `input${Math.trunc(Math.random() * 100500)}`
  }

}
